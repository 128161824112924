import React from 'react';
import Tilty from 'react-tilty';

const BcrumbBannerOne = ({ title, paragraph, styleClass, mainThumb }) => {
  const getTransformStyle = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 480) return "translateY(0px)"; // Mobile
    if (screenWidth <= 768) return "translateY(-70px)"; // Tablet (more negative translation)
    return "translateY(-100px)"; // Desktop and larger screens
    return "translateY(-100px)";
  };

  return (
    <div className='breadcrum-area breadcrumb-banner'>
      <div className='container'>
        <div className='section-heading heading-left'>
          <h1 className='title h2' dangerouslySetInnerHTML={{ __html: title }}></h1>
          <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        </div>
        <div className={`banner-thumbnail ${styleClass}`}>
          <Tilty perspective={2000} reset={false}>
            <img
              src={mainThumb}
              alt='Illustration'
              style={{
                maxWidth: "100%",
                maxHeight: "500px",
                width: "auto",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                transform: getTransformStyle()
              }}
            />
          </Tilty>
        </div>
      </div>
      </div>
    );
}

export default BcrumbBannerOne;