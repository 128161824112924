import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import AboutFive from "../component/about/_AboutFive";
import AboutFour from "../component/about/_AboutFour";
import AboutThree from "../component/about/_AboutThree";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProcessOne from "../component/process/ProcessOne";
import BcrumbBannerOne from "../elements/breadcrumb/_BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';


const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerTwo 
                title="One of the fastest growing agency"
                paragraph ="We at Axellier excel in delivering diverse digital solutions globally. Our dedicated teams ensure a secure, impactful, and innovative online presence for our clients."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-3.png"
                />
                <AboutFour />
                <AboutThree />
                <AboutFive />
                <ProcessOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;