import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Link } from "react-router-dom";

const AboutFour = () => {
  return (
    <div className='section section-padding case-study-featured-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-7 col-lg-6'>
            <div className='case-study-featured-thumb text-start'>
              <img src={"/images/others/case-study-4.png"} alt='travel' />
            </div>
          </div>
          <div className='col-xl-5 col-lg-6'>
            <div className='case-study-featured'>
              <div className='section-heading heading-left'>
                <span className='subtitle'>Who we are</span>
                <h2 className='title'>Building software for world changers</h2>
                <p>
                  Donec metus lorem, vulputate at sapien sit amet, auctor
                  iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus
                  velit. Aliquam tristique libero at dui sodales, et placerat
                  orci lobortis.
                </p>
                <p>
                  Maecenas ipsum neque, elementum id dignissim et, imperdiet
                  vitae mauris.
                </p>
                <Link to='#' className='axil-btn btn-fill-primary btn-large'>
                  Read More
                </Link>
              </div>
              <div className='case-study-counterup'>
                <div className='single-counterup'>
                  <h2 className='count-number'>
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className='number count'>
                          {isVisible ? <CountUp end='10' duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className='symbol'>+</span>
                  </h2>
                  <span className='counter-title'>Years on the market</span>
                </div>
                <div className='single-counterup'>
                  <h2 className='count-number'>
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className='number count'>
                          {isVisible ? (
                            <CountUp end='1500' duration={1} />
                          ) : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className='symbol'>+</span>
                  </h2>
                  <span className='counter-title'>
                    Projects delivered so far
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
