import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BrandItem from "../component/brand/BrandItem";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BcrumbBannerOne from "../elements/breadcrumb/_BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const OurClients = () => {

    return (
        <>
        <SEO title="Our Clients" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Our Clients"
                paragraph ="We work closely with our clients in order to deliver the best results"
                styleClass="thumbnail-2"
                mainThumb="/images/banner/banner-thumb-2.png"
                />
                <div className="section brand-wrap-area bg-color-dark">
                    <div className="container">
                        <div className='row'>
                        <BrandItem />
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default OurClients;