import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import Nav from "./Nav";

const MobileMenu = ({ MobileHandler }) => {
  return (
    <div className='mobilemenu-popup'>
      <div className='mobilemenu-inner'>
        <div className='mobilemenu-header'>
          <div className='mobile-nav-logo'>
            <Link to={"/"}>
              <img
                className='light-mode'
                src={"/images/logo.png"}
                alt='Site Logo'
              />
              <img
                className='dark-mode'
                src={"/images/logo.png"}
                alt='Site Logo'
              />
            </Link>
          </div>
          <button className='mobile-menu-close' onClick={MobileHandler}>
            <FaTimes />
          </button>
        </div>
        <div className='mobilemenu-body'>
          <Nav />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
