import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/_ProjectOne";
import TeamOne from "../component/team/TeamOne";
import WhyChooseOne from "../component/whychoose/WhyChooseOne";
import BcrumbBannerOne from "../elements/breadcrumb/_BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";


const OurOffice = () => {

    return (
        <>
        <SEO title="Our Office" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Our Office"
                paragraph ="This is the place weher we create best solution for you with our experienced Team Member"
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-3.png"
                />
                <WhyChooseOne />
                <ProjectOne />
                <TeamOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default OurOffice;