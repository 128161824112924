import React from 'react';
import { Link } from "react-router-dom";
import ProjectData from "../../data/project/ProjectData.json";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectPropOne from "./itemProp/ProjectPropOne";

const portfolioData = ProjectData;

const ProjectFour = () => {
  return (
    <div className='section section-padding-equal bg-color-dark'>
      <div className='container'>
        <SectionTitle
          subtitle='Case study'
          title='Selected projects'
          description='At Axellier, we are not just creating websites or applications, we are crafting digital experiences that resonate and engage. Our portfolio showcases a diverse range of projects, each reflecting our commitment to excellence, innovation, and client satisfaction.'
          textAlignment='heading-light-left mb--90'
          textColor=''
        />
        <div
          className='project-add-banner'
          onClick={() => window.open("https://homec.ch/", "_blank")}
          style={{
            transition: "transform 0.2s",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.transform = "scale(1.02)")
          }
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <div className='content'>
            <span className='subtitle'>
              Streamlining Real Estate Management in Switzerland and Germany
            </span>
            <h3 className='title'>HOMEC</h3>
            <a
              href='https://homec.ch/'
              target='_blank'
              style={{ textDecoration: "none" }}
            >
              <div className='brand-logo' onClick={window.locarion}>
                <img src={"/images/project/godaddy.png"} alt='HomeC' />
              </div>
            </a>
          </div>
          <div
            className='thumbnail'
            style={{ width: "50%", overflow: "hidden" }}
          >
            <img src={"/images/project/mobile-mockup.png"} alt='Mockup' />
          </div>
        </div>

        <div className='row row-45'>
          {portfolioData
            .slice(0, 7)
            .filter((data, index) => index !== 4)
            .map((data) => (
              <div className='col-md-6' key={data.id}>
                <ProjectPropOne
                  projectStyle='project-style-2'
                  portfolio={data}
                />
              </div>
            ))}
        </div>
        <div className='more-project-btn'>
          <Link to={"/projects"} className='axil-btn btn-fill-white'>
            Discover More Projects
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectFour;