import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import FaqOne from "../component/faq/FaqOne";
import PricingOne from "../component/pricing/PricingOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const PricingTable = () => {


  const style = {
    padding: '160px 0 60px'
  }

  return (
    <>
      <SEO title='Promotions' />
      <ColorSwitcher />
      <main className='main-wrapper'>
        <HeaderOne />
        {/* <BreadCrumbOne title='Promotions' page='Promotions' /> */}
        <div className='section section-padding' style={style}>
          <div className='container'>
            <SectionTitle
              // subtitle='Promotions'
              title='Grow Your Business Online – Choose the Right Digital Solution.'
              description='We handle everything, so you can focus on running your business.'
              textAlignment='mb-0'
              textColor=''
            />
            <PricingOne />
          </div>
          <ul className='shape-group-3 list-unstyled'>
            <li className='shape shape-2'>
              <img src={"/images/others/bubble-4.png"} alt='shape' />
            </li>
          </ul>
        </div>
        <FaqOne />
        <CtaLayoutOne />
        <FooterOne parentClass='' />
      </main>
    </>
  );
};

export default PricingTable;