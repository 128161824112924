import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CounterUpTwo from "../component/counterup/CounterUpTwo";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import TeamOne from "../component/team/TeamOne";
import VideoTwo from "../component/video/VideoTwo";
import BcrumbBannerOne from "../elements/breadcrumb/_BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const Team = () => {

    return (
        <>
        <SEO title="Team" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="Meet talent &amp; experience"
                paragraph ="Grow your business online by awesomely designed mobile apps that fits all types."
                styleClass="thumbnail-2"
                mainThumb="/images/banner/banner-thumb-2.png"
                />
                <CounterUpTwo />
                <TeamOne />
                <VideoTwo />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Team;