import React from 'react';
import SEO from "../common/SEO";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/_ProjectOne";
import BcrumbBannerOne from "../elements/breadcrumb/_BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';

const ProjectGridTwo = () => {
  return (
    <>
      <SEO title='Project Three Column' />
      <ColorSwitcher />
      <main className='main-wrapper'>
        <HeaderOne />
        <BcrumbBannerTwo
          title='Our Projects'
          paragraph='A quick view of industry specific problems solved with design by the awesome team at Axellier.
                '
          styleClass=''
          mainThumb='/images/banner/banner-thumb-1.gif'
        />
        <ProjectOne colSize='col-xl-4 col-md-6' columnGap='row-15' />
        <CtaLayoutOne />
        <FooterOne parentClass='' />
      </main>
    </>
  );
};

export default ProjectGridTwo;