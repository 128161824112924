import React from "react";
import FormOne from "../contact/_FormOne";
import FormTwo from "../contact/FormTwo";

const AboutOne = () => {
  return (
    <section className='section section-padding-equal bg-color-light'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='about-us'>
              <div className='section-heading heading-left mb-0'>
                <span className='subtitle'>About Us</span>
                <h2 className='title mb--40'>
                  Crafting Digital Journeys With Care.
                </h2>
                <p>
                  Driven by passion and innovation, we at Axellier empower
                  brands across a spectrum of digital avenues. From crafting
                  visually stunning designs to deploying robust software
                  solutions, our multidisciplinary team ensures every touchpoint
                  resonates with excellence.
                </p>
                <p>
                  We're more than just a service provider; we're your digital
                  partner. As you navigate the ever-evolving digital realm, our
                  comprehensive suite of services ensures you're always ahead of
                  the curve.
                </p>
              </div>
            </div>
          </div>

          <div className='col-xl-5 col-lg-6 offset-xl-1'>
            <div className='contact-form-box'>
              <h3 className='title'>Get a free Keystroke quote now</h3>
              <FormTwo />
            </div>
          </div>
        </div>
      </div>
      <ul className='shape-group-6 list-unstyled'>
        <li className='shape shape-1'>
          <img src={"/images/others/bubble-7.png"} alt='Bubble' />
        </li>
        <li className='shape shape-6'>
          <img src={"/images/others/bubble-40.png"} alt='Bubble' />
        </li>
        <li className='shape shape-2'>
          <img src={"/images/others/line-4.png"} alt='line' />
        </li>
        <li className='shape shape-3'>
          <img src={"/images/others/line-5.png"} alt='line' />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
